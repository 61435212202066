// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseImages = process.env.VUE_APP_ROOT_PATH+'Images/';
    public static login = API.webApiBase + 'Login';
    public static usuario = API.webApiBase + 'usuarios';
    public static roles = API.webApiBase + 'Roles';
    public static VersionBack = API.webApiBase + 'VersionBack';
    /*************************** */
    public static servidor_smtp = API.webApiBase + 'servidor_smtp';
    public static SendingEmails = API.webApiBase + 'SendingEmails';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static ayuntamiento = API.webApiBase + 'ayuntamiento';
    public static analitica_web = API.webApiBase + 'analitica_web';
    public static tipos_de_analitica = API.webApiBase + 'tipos_de_analitica';
    public static AnaliticaWeb = API.webApiBase + 'AnaliticaWeb?codigo=';
    public static AnaliticaWebRedirect = API.webApiBase + 'AnaliticaWeb/redirect?codigo=';
    public static registro_analitica_web = API.webApiBase + 'registro_analitica_web';
    public static InfoTurist = API.webApiBase + 'InfoTurist';
    public static comunidad_autonoma = API.webApiBase + 'comunidad_autonoma';
    public static provincias = API.webApiBase + 'provincias';
    public static respuestas = API.webApiBase + 'respuestas';
    public static preguntas = API.webApiBase + 'preguntas';
    public static ExcelImport = API.webApiBase + 'ExcelImport';
    public static dashboard = API.webApiBase + 'dashboards';
    public static agrupacion_importacionesDto = API.webApiBase + 'agrupacion_importaciones';
    public static mapping_question = API.webApiBase + 'mapping_questions';
    public static Connect = API.webApiBase + 'Connect';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
